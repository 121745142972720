<template>
  <div class="hit-content">
    <!-- 命中位數獎勵 -->
    <div class="hit-wrap">
      <div class="hit-box" v-for="item in 6" :key="item">
        <div
          class="title"
          v-html="$t('lottery.history.MatchFirst', { number: item })"
        ></div>
        <div class="meb-number">{{ item }}4,128 MEB</div>
        <div class="meb-value">~$47,796</div>
      </div>
    </div>
    <!-- dao生態基金 -->
    <div class="hit-box dao-box">
      <div class="title">{{ history.DaoFund }}</div>
      <div class="meb-number">4,128 MEB</div>
      <div class="meb-value">~$47,796</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NumMatch',
  props: {},
  data () {
    return {}
  },
  computed: {
    history () {
      return this.$t('lottery.history')
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.hit-content {
  .hit-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .hit-box {
    width: 50%;
    margin: 20px auto 0 0;
    .title {
      color: #a85ffc;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 0;
    }
    .meb-number {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
      color: #4c5968;
    }
    .meb-value {
      color: #818086;
      font-size: 12px;
      line-height: 1;
    }
  }
  .dao-box {
    width: 100%;
    .title {
      font-weight: bold;
    }
  }
}
</style>
