<template>
  <VanPopup
    v-model="popupShow"
    @click-overlay="closePop"
    style="width: 93%; max-width: 380px"
    round
    get-container="content"
  >
    <div class="wrapper">
      <div class="header">
        <div class="title">
          {{ pop.round }}
          <span class="num">{{roundIndex}}</span>
        </div>
        <div class="close-wrapper">
          <div class="close-icon" @click="closePop">✕</div>
        </div>
      </div>
      <div class="random-panel">
        <div class="yours">{{ pop.your }}</div>
        <div class="lottery-group">
          <div class="item" v-for="(item, index) in myTickets" :key="item.id">
            <div class="title">#{{$gbUtils.PrefixInteger(index+1)}}</div>
            <div class="num">
              <span v-for="(item, index) in num" :key="item + index">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="button" @click="closePop">{{ pop.ok }}</div>
    </div>
  </VanPopup>
</template>

<script>
export default {
  props: {
    roundIndex: {
      default () {
        return 0
      }
    },
    myTickets: {
      default () {
        return []
      }
    }
  },
  data () {
    return {
      popupShow: true,
      num: '152356'
    }
  },
  computed: {
    pop () {
      return this.$t('lottery.pop')
    }
  },
  methods: {
    // 关闭弹窗
    closePop () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 300px;
  max-height: 80vh;
  padding-bottom: 35px;
  box-sizing: border-box;
  .header {
    padding: 15px 30px;
    border-bottom: 1px solid #e3e3e3;
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    .title {
      display: flex;
      align-items: center;
    }
    span {
      display: inline-block;
      width: 55px;
      height: 25px;
      background: rgba(168, 95, 252, 0.05);
      border: 1px solid rgba(168, 95, 252, 0.5019607843137255);
      border-radius: 15px;
      line-height: 25px;
      text-align: center;
      margin-left: 10px;
      font-weight: normal;
      font-size: 14px;
    }
    .close-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .close-icon {
        box-sizing: border-box;
        cursor: pointer;
        width: 20px;
        height: 20px;
        background: #66eab9 !important;
        border-radius: 10px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
      }
    }
  }
  .random-panel {
    padding: 0 30px;
    margin-top: 20px;
    .lottery-group {
      max-height: 200px;
      overflow-y: scroll;
    }
    .yours {
      font-weight: 700;
      padding-bottom: 20px;
    }
    .item {
      display: flex;
      align-content: center;
      height: 30px;
      line-height: 30px;
      margin-bottom: 10px;
      justify-content: space-between;
      .num {
        box-sizing: border-box;
        background: #f0f0f0;
        width: 80%;
        border-radius: 15px;
        font-weight: 700;
        display: flex;
        justify-content: space-around;
        padding: 0 20px;
      }
    }
  }
  .button {
    margin: 0 30px;
    margin-top: 30px;
    height: 50px;
    background: #66eab9;
    border-radius: 15px;
    font-weight: 700;
    line-height: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
  }
}
</style>
