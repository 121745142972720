<template>
  <div class="lottery">
    <header>
      <div class="round">#{{roundIndex}}</div>
      <div class="notice">
        <span class="normal">{{ main.rewardNotice1 }} </span>
        <span>3{{ main.hour }} 28{{ main.min }}</span>
        <span class="normal">{{ main.rewardNotice2 }} </span>
      </div>
    </header>
    <section class="pool">
      <div class="title">{{ history.Prizepot }}</div>
      <div class="num">~$198,436</div>
      <div class="meb">2341243.45 MEB</div>
      <!-- 即将销售 -->
      <template v-if="isSale">
        <svg class="sale-icon" aria-hidden="true">
          <use xlink:href="#icon-funnel"></use>
        </svg>
        <div class="btn">
          <button class="disabled" disabled>{{ main.comingSoon }}</button>
        </div>
      </template>
      <!-- 正在销售 -->
      <template v-else>
        <div class="btn">
          <button @click="handleToBuy">{{ main.BuyTickets }}</button>
        </div>
        <div class="time">{{ main.DrawTime }}：2022-01-13 08:16 UTC+8</div>
      </template>
    </section>
    <section class="panel">
      <div class="wrapper">
        <div class="header">
          <!-- <span>本回合您有 <span class="num">20</span> 张**</span> -->
          <span v-html="$t('lottery.main.haveTickets', { number: (myTickets && myTickets.length) || 0 })"></span>
          <span class="check" @click="handleToCheck">{{
            main.viewTickets
          }}</span>
        </div>
        <div class="content">
          <div class="main-title">{{ main.winPrize }}</div>
          <!-- 命中位數獎勵 -->
          <NumMatch></NumMatch>
        </div>
      </div>
    </section>
    <section class="bottom">
      <div class="item" @click="$gbUtils.handleToPage('/lotteryHistroy')">
        <span>{{ main.history }}</span
        ><van-icon class="icon" name="arrow" />
      </div>
      <div class="item" @click="$gbUtils.handleToPage('/lotteryRule')">
        <span>{{ main.gameIntroduction }}</span
        ><van-icon class="icon" name="arrow" />
      </div>
      <div class="item" @click="$gbUtils.handleToPage('/myLottery')">
        <span>{{ main.my }}</span
        ><van-icon class="icon" name="arrow" />
      </div>
    </section>
    <BuyLotteryPop v-if="buyLotteryIsShow" @close="handleToBuy"></BuyLotteryPop>
    <CheckLotteryPop
      :roundIndex="roundIndex"
      :myTickets="myTickets"
      v-if="checkLotteryIsShow"
      @close="handleToCheck"
    ></CheckLotteryPop>
  </div>
</template>

<script>
import NumMatch from './components/NumMatch.vue'
import BuyLotteryPop from './components/BuyLotteryPop.vue'
import CheckLotteryPop from './components/CheckLotteryPop.vue'
export default {
  components: { NumMatch, BuyLotteryPop, CheckLotteryPop },
  name: 'Lottery',
  data () {
    return {
      roundIndex: 235,
      buyLotteryIsShow: false,
      checkLotteryIsShow: false,
      isSale: false, // 是否正在售票 true 是，false 否 等待
      myTickets: [
        {
          id: 1,
          num: '123456'
        }, {
          id: 2,
          num: '223456'
        }, {
          id: 3,
          num: '323456'
        }, {
          id: 4,
          num: '423456'
        }, {
          id: 5,
          num: '523456'
        }, {
          id: 6,
          num: '623456'
        }
      ]
    }
  },
  computed: {
    main () {
      return this.$t('lottery.main')
    },
    history () {
      return this.$t('lottery.history')
    }
  },
  methods: {
    handleToBuy () {
      this.buyLotteryIsShow = !this.buyLotteryIsShow
    },
    handleToCheck () {
      this.checkLotteryIsShow = !this.checkLotteryIsShow
    }
  },
  mounted () { }
}
</script>

<style lang="scss" scoped>
.lottery {
  background-color: #a85ffc;
  // min-height: 100vh;
  .normal {
    font-weight: normal;
  }
  header {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    padding: 0 23px;
    height: 30px;
    line-height: 30px;
  }
  .pool {
    height: 310px;
    background: linear-gradient(180deg, #a85ffc 0%, #54307e 100%);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 10px 10px;
    color: #f0f0f1;
    font-size: 15px;
    text-align: center;
    .title {
      padding-top: 30px;
      line-height: 1;
      font-weight: bold;
    }
    .num {
      padding-top: 10px;
      font-size: 48px;
      line-height: 1.5;
      font-weight: bold;
      color: #fec52d;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
    .meb {
      line-height: 1;
      font-weight: bold;
    }
    .btn {
      margin: 0 auto;
      margin-top: 30px;
      height: 70px;
      width: 161px;
      background: url('../../static/img/lottery/lottery-bg.png') no-repeat;
      animation: 3s ease-in-out 0s infinite normal none running grkjUd;
      button {
        margin-top: 13px;
        width: 120px;
        height: 38px;
        border-radius: 10px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        border: none;
        background: linear-gradient(180deg, #a85ffc 0%, #54307e 100%);
        cursor: pointer;
        &.disabled {
          background: linear-gradient(180deg, #c1c1c1 0%, #5e5e62 100%);
          cursor: pointer;
        }
      }
    }
    .time {
      line-height: 1.5;
      font-size: 12px;
      margin-top: 15px;
    }
    .sale-icon {
      height: 25px;
      margin-top: 20px;
    }
    @keyframes grkjUd {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(6deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
  .panel {
    margin: 20px 0;
    padding: 0 15px;
    box-sizing: border-box;
    .wrapper {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
      min-height: 475px;
      padding-bottom: 20px;
      box-sizing: border-box;
      .header {
        height: 60px;
        background: #eeedf3;
        border-radius: 10px 10px 0px 0px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 60px;
        .num {
          font-weight: bold;
        }
        .check {
          color: #7b8fbb;
          text-decoration: underline;
          line-height: 22px;
        }
      }
      .content {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        .main-title {
          font-weight: bold;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px dashed #e3e3e3;
          text-align: center;
        }
      }
    }
  }
  .bottom {
    height: 163px;
    background: #313a59;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 52px;
      line-height: 52px;
      margin: 0 20px;
      border-bottom: 1px dashed #e3e3e381;
      padding: 0 10px;
      span {
        color: #e3e3e3bd;
      }
      .icon {
        color: #a85ffc;
        font-weight: 700;
      }
    }
    .item:last-child {
      border: none;
    }
  }
}
</style>
