<template>
  <VanPopup
    v-model="popupShow"
    @click-overlay="closePop"
    style="width: 93%; max-width: 380px"
    round
    get-container="content"
  >
  <!-- 購買彩票 -->
    <div class="wrapper" v-if="!isCheck">
      <div class="header">
        <div class="title">{{main.BuyTickets}}</div>
        <div class="close-wrapper">
          <div class="close-icon" @click="closePop">✕</div>
        </div>
      </div>
      <div class="content">
        <div class="title">
          <span class="buy">{{pop.Buy}}</span>
          <span class="lottery">{{pop.Tickets}}</span>
        </div>
        <div class="input-wrap">
          <input type="tel"
          placeholder="0" v-model="ticketsCount"/>
          <div class="num">~{{needMeb}} MEB</div>
        </div>
        <!-- 快速选择按键 -->
        <div class="select-wrap">
          <div class="item" @click="choseTickets(1)">1</div>
          <div class="item" @click="choseTickets(5)">5</div>
          <div class="item" @click="choseTickets(20)">20</div>
          <div class="item" @click="choseTickets(0)">MAX</div>
        </div>
        <!-- 支付，余额信息 -->
        <div class="num-panel">
          <div class="need-wrap">
            <div class="title">{{pop.needPay}}</div>
            <div class="num">~{{needMeb}} MEB</div>
          </div>
          <div class="balance" @click="approveMEBContract">
            <span>MEB {{ pop.Balance }}:&nbsp;</span>
            <VanLoading size="12" color="#000" v-if="loadingMeb"/>
            <span v-else>{{mebBalance || 0}}</span>
          </div>
        </div>
        <!-- 启用按钮 -->
        <div class="button" v-if="!authMeb">
          <VanLoading color="#fff" v-if="lodingAuthMeb"/>
          <span v-else>{{pop.Enable}}</span>
        </div>
        <template v-else>
        <!-- 購買按鈕 -->
        <div class="button" :class="{'disabled': ticketsCount <= 0 || mebBalance <= 0}">{{pop.BuyInstantly}}</div>
        <!-- 查看&编辑按钮 -->
        <div class="check-button" :class="{'disabled': ticketsCount <= 0 || mebBalance <= 0}" @click="handleCheckNumber">
          <span>
          {{pop.View}}/{{pop.Edit}}<span v-html="pop.space"></span>{{pop.Numbers}}
          </span>
          </div>
        </template>
        <div class="notice">
          {{pop.buyTips}}
        </div>
      </div>
    </div>
    <!-- 查看何編輯彩票 -->
    <div class="wrapper wapper-check" v-else>
      <div class="header">
        <div class="title">
          <i class="iconfont icon-a-zu201 arrow" @click="handleCheckNumber"></i>
          <span>{{pop.Edit}}<span v-html="pop.space"></span>{{pop.Numbers}}</span>
        </div>
        <div class="close-wrapper">
          <div class="close-icon" @click="closePop">✕</div>
        </div>
      </div>
      <div class="content">
        <div class="num-panel">
          <div class="need-wrap">
            <div class="title">{{pop.needPay}}</div>
            <div class="num">~{{needMeb}} MEB</div>
          </div>
          <div class="balance">MEB {{pop.Balance}}: {{mebBalance}}</div>
        </div>
        <div class="notice">
          {{pop.buyTips}}
        </div>
        <!-- 随机选取 -->
        <div class="random-btn" @click="geRandList">{{pop.randomlySelected}}</div>
        <div class="random-panel">
          <div class="item" v-for="(item, index) in tempTickets" :key='"ticket" + item'>
            <div class="title">#{{$gbUtils.PrefixInteger(index + 1)}}</div>
            <div class="num">
              <span v-for="(child, childIndex) in item" :key="child + childIndex">{{ child }}</span>
            </div>
          </div>
        </div>
        <div class="button">{{pop.buyNow}}</div>
        <div class="back"  @click="handleCheckNumber">
          <i class="iconfont icon-a-zu201 arrow"></i>
          <span>{{pop.back}}</span>
        </div>
      </div>
    </div>
  </VanPopup>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config/app.config'
import pancakeObj from '@/utils/pancakeRouter'
const contractsInfo = config.contractsInfo

export default {
  name: 'BuyLotteryPop',
  data () {
    return {
      tokenMeb: contractsInfo.tokenGEPContract,
      isCheck: false,
      popupShow: true,
      ticketsCount: null,
      countMax: 100, // 购买最大数量
      ticketPrice: 10, // 一張票價值 -- 默認 10個meb
      needMeb: 0, // 需要多少meb购买
      lodingAuthMeb: false, // meb執行啟用loading
      mebAllowance: 0, // meb授权额度
      authMeb: false, // 启用标识 true啟用
      loadingMeb: false, // 獲取meb余额loading
      mebBalance: 0, // meb余额
      num: '952346',
      tempTickets: [] // 随机票列表
    }
  },
  computed: {
    ...mapState(['myAcount']),
    main () {
      return this.$t('lottery.main')
    },
    pop () {
      return this.$t('lottery.pop')
    }
  },
  watch: {
    ticketsCount (val) {
      this.needMeb = val * this.ticketPrice
    }
  },
  methods: {
    init () {
      this.getBalance()
      this.getAllowance()
    },
    // 查看号码
    handleCheckNumber () {
      if (this.authMeb && this.ticketsCount > 0 && this.mebBalance > 0) {
        this.isCheck = !this.isCheck
      }
    },
    // 关闭弹窗
    closePop () {
      this.$emit('close')
    },
    // 获取余额
    async getBalance () {
      this.loadingMeb = true
      const mebResp = await this.$web3.balanceOf(this.tokenMeb)
      this.loadingMeb = false
      if (mebResp.success) {
        this.mebBalance = this.$gbUtils.formatTwoBalance(mebResp.result) || 0
      }
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.lodingAuthMeb = true
      const allanceResp = await pancakeObj.allowance(this.tokenMeb)
      if (allanceResp.success) {
        this.mebAllowance = parseFloat(allanceResp.result)
        const authMEB = 555 > 0
        this.authMeb = authMEB
      } else {
        this.authMEB = false
      }
      this.lodingAuthMeb = false
    },
    // 授权
    async approveMEBContract () {
      if (this.lodingAuthMeb) return false
      this.lodingAuthMeb = true
      const MEBObj = {
        abi: this.tokenMeb.abi,
        address: this.tokenMeb.address
      }
      const resp = await pancakeObj.approveContract(MEBObj, 1000000000000000) // 授权MEB
      this.lodingAuthMeb = false
      if (!resp.success) return this.$toast(resp.message.message)
      this.getAllowance()
    },
    // 快速选择
    choseTickets (num) {
      if (num && num > 0) {
        this.ticketsCount = num
      } else {
        const max = parseInt(this.mebBalance / this.ticketPrice)
        this.ticketsCount = max > this.countMax ? this.countMax : max
      }
    },
    // 隨即生成6位數
    getRandom () {
      var res = ''
      for (var i = 0; i < 5; i++) {
        res += Math.floor(Math.random() * 10)
      }
      return res
    },
    geRandList () {
      const list = []
      for (var i = 0; i < this.ticketsCount; i++) {
        const temp = this.getRandom()
        list.push(temp)
      }
      this.tempTickets = list
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 300px;
  max-height: 80vh;
  padding-bottom: 20px;
  box-sizing: border-box;
  .header {
    padding: 0 30px;
    border-bottom: 1px solid #e3e3e3;
    height: 65px;
    line-height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    box-sizing: border-box;
    .title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      i {
        font-size: 12px;
        color: #66eab9;
        cursor: pointer;
        padding-right: 10px;
      }
    }
    .close-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .close-icon {
        box-sizing: border-box;
        cursor: pointer;
        width: 20px;
        height: 20px;
        background: #66eab9 !important;
        border-radius: 10px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
      }
    }
  }
  .content {
    padding: 15px 30px 0 30px;
    max-height: calc(80vh - 67px);
    box-sizing: border-box;
    overflow-y: auto;
    .title {
      display: flex;
      justify-content: space-between;
      .buy {
        color: #818086;
      }
    }
    .input-wrap {
      box-sizing: border-box;
      padding: 10px 30px;
      height: 80px;
      margin-top: 20px;
      color: #4c5968;
      text-align: right;
      border-radius: 10px;
      background-color: #f0f0f0;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input {
        text-align: right;
        width: 100%;
        background-color: #f0f0f0;
        height: 30px;
        border: none;
      }
      .num {
        font-size: 12px;
      }
    }
    .select-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      .item {
        height: 15px;
        border-radius: 10px;
        background: #66eaba54;
        width: 70px;
        line-height: 15px;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        cursor: pointer;
      }
    }
    .num-panel {
      margin-top: 30px;
      .need-wrap {
        display: flex;
        justify-content: space-between;
        color: #16172a;
        .num {
          font-weight: 700;
        }
      }
      .balance {
        padding-top: 10px;
        font-size: 12px;
        color: #4c5968;
        /deep/ .van-loading {
          display: inline-block;
        }
      }
    }
    .button {
      margin-top: 40px;
      height: 50px;
      background: #66eab9;
      border-radius: 15px;
      font-weight: 700;
      line-height: 50px;
      cursor: pointer;
      text-align: center;
      font-size: 15px;
      &.disabled {
        background-color: rgb(233, 234, 235);
        color: rgb(189, 194, 196);
        cursor: not-allowed;
      }
    }
    .check-button {
      margin-top: 20px;
      height: 50px;
      color: #7b8fbb;
      border: 2px solid #7b8fbb;
      border-radius: 15px;
      font-weight: 700;
      line-height: 50px;
      cursor: pointer;
      text-align: center;
      font-size: 15px;
       &.disabled {
        border-color: rgb(233, 234, 235);
        color: rgb(189, 194, 196);
        cursor: not-allowed;
      }
    }
    .notice {
      margin-top: 20px;
      color: #818086;
      font-size: 10px;
    }
  }
}
.wapper-check {
  .num-panel {
    margin-top: 0px !important;
  }
  .random-btn {
    height: 33px;
    border: 2px solid #a85ffc;
    margin-top: 20px;
    border-radius: 15px;
    color: #a85ffc;
    text-align: center;
    line-height: 33px;
    cursor: pointer;
  }
  .random-panel {
    margin-top: 20px;
    .item {
      display: flex;
      align-content: center;
      height: 30px;
      line-height: 30px;
      margin-bottom: 10px;
      justify-content: space-between;
      .num {
        box-sizing: border-box;
        background: #f0f0f0;
        width: 80%;
        border-radius: 15px;
        font-weight: 700;
        display: flex;
        justify-content: space-around;
        padding: 0 20px;
      }
    }
  }
  .back {
    padding: 20px;
    text-align: center;
    color: #7b8fbb;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    i {
      padding-right: 5px;
      font-weight: normal;
      font-size: 12px;
    }
  }
}
</style>
